<template>
  <div class="v-stack h-stretch gap-3">
    <label class="text-left required">User Type</label>
    <select v-model="mode">
      <option value="crew">Crew</option>
      <option value="freelancers">Freelancer</option>
    </select>
    <label class="text-left required">Profession</label>
    <select v-model="filter">
      <option v-for="category in categories" :key="category">
        {{ category }}
      </option>
    </select>
    <label class="text-left required">User</label>
    <select v-model="user" @change="onUserSelect()">
      <option v-for="user in filteredUsers" :key="user._id" :value="user._id">
        {{ user.fullName }}
      </option>
    </select>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  data() {
    return {
      mode: "crew",
      user: "",
      users: [],
      filter: "All",
    };
  },
  props: {
    value: {
      type: String,
      default: "",
    },
    roles: {
      type: Array,
      default: () => [],
    },
  },
  watch: {
    value() {
      this.user = this.value;
    },
  },
  computed: {
    categories() {
      let categories = [];
      let users = this.mode == "crew" ? this.lifecrew : this.freelancers;
      for (const user of users) {
        categories = categories.concat(
          user.categories.map((item) => item.name)
        );
      }
      return ["All"].concat(
        Array.from(new Set(categories)).sort((a, b) => a.localeCompare(b, "cs"))
      );
    },
    filteredUsers() {
      let users = this.mode == "crew" ? this.lifecrew : this.freelancers;

      if (this.filter == "All") {
        return users;
      }

      return users.filter((user) =>
        user.categories.map((item) => item.name).includes(this.filter)
      );
    },
    lifecrew() {
      return this.users
        .filter((user) => user.accessLevel > 4)
        .filter((user) => {
          for (const category of user.categories) {
            if (this.roles.includes(category.name)) {
              return true;
            }
          }
          return false;
        })
        .sort((a, b) => a.fullName.localeCompare(b.fullName, "cs"));
    },
    freelancers() {
      return this.users
        .filter((user) => user.accessLevel == 0)
        .filter((user) => {
          for (const category of user.categories) {
            if (this.roles.includes(category.name)) {
              return true;
            }
          }
          return false;
        })
        .sort((a, b) => a.fullName.localeCompare(b.fullName, "cs"));
    },
  },
  methods: {
    ...mapActions(["getUsers"]),
    refresh() {
      this.getUsers()
        .then((users) => {
          this.users = users;

          for (const user of this.freelancers) {
            if (user._id == this.value) {
              this.mode = "freelancers";
            }
          }
          this.user = this.value;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    onUserSelect() {
      this.$emit("input", this.user);
    },
  },
  mounted() {
    this.refresh();
  },
};
</script>

<style lang="scss" scoped>
.card {
  position: absolute;
  top: 30px;
  width: 80%;
  max-width: 800px;
}

a {
  cursor: pointer;
  font-weight: bold;
  color: #2c3e50;

  &:hover {
    text-decoration: underline;
  }

  &.active {
    color: #42b983;
    text-decoration: underline;
  }
}
</style>